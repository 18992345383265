import React, { useContext, useRef } from "react";
import {
  StyledHiveTechCrowdMenu,
  StyledHiveTechCrowdMenuItem,
  StyledHiveTechCrowdMenuItemImage,
} from "./style.jsx";
import { DataContext } from "../../contexts/DataContext";
import useTheme from "../../hooks/useTheme";

const HiveTechCrowdMenu = ({
  isSectionMenu = false,
}) => {
  const {
    menu, selectedSectionId, setSelectedSectionId, showTopMenu, setShowTopMenu, 
  } = useContext(DataContext);
  const themeColor = useTheme();
  const menuRef = useRef();
  
  /*useEffect(()=>{
    if (showTopMenu) {
      if (isSectionMenu) {
        menuRef.current.classList.add('hide')
        menuRef.current.classList.remove('show')
      } else {
        menuRef.current.classList.add('show')
        menuRef.current.classList.remove('hide')
      }
    } else {
      if (isSectionMenu) {
        menuRef.current.classList.add('show')
        menuRef.current.classList.remove('hide')
      } else {
        menuRef.current.classList.add('hide')
        menuRef.current.classList.remove('show')
      }
    }
  }, [showTopMenu])*/

  /*useEffect(() => {
    if (isSectionMenu) {
      const listener = (e) => {
        const rect = menuRef.current.getBoundingClientRect();
        const offsetTop = rect.top - 64;
        if (offsetTop < 0) {
          setShowTopMenu(true)
        } else {
          setShowTopMenu(false)
        }
      }
      window.addEventListener("scroll", listener);
      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  }); */
  
  return (
    <StyledHiveTechCrowdMenu {...themeColor} isSectionMenu={isSectionMenu} className={isSectionMenu ? "col-70" : null} ref={menuRef}>
      {
        menu.tecnologia.map((item, index) => (
          <StyledHiveTechCrowdMenuItem 
            key={index} 
            onClick={() => setSelectedSectionId(item.id)} 
            isSelected={selectedSectionId == item.id}>
            <StyledHiveTechCrowdMenuItemImage src={item.image} loading="lazy"/>
            {item.label}
          </StyledHiveTechCrowdMenuItem>
        ))
      }
    </StyledHiveTechCrowdMenu>
  );
};

export default HiveTechCrowdMenu;
